import Error from './Error';
import Modal from './Modal';
import PlayButton from './PlayButton';
import SayMyName, { audioData } from './SayMyName';
import Share from './Share';
import SoundWaves from './SoundWaves';
import Analytics from './Analytics';
import Donate from './Donate';
import { delay } from './helpers';

export default class SayNameForm {
  donate: Donate = new Donate();
  form: HTMLFormElement | null;
  sayMyName: SayMyName = new SayMyName('data');
  playButton: PlayButton = new PlayButton();
  modal: Modal = new Modal(document.getElementById('shareModal'));
  waves: SoundWaves = new SoundWaves();
  analytics = new Analytics();
  shareQrButton: HTMLButtonElement = document.getElementById(
    'shareQRButton'
  ) as HTMLButtonElement;
  shareLinkButton: HTMLButtonElement = document.getElementById(
    'shareLinkButton'
  ) as HTMLButtonElement;
  shareButtonsWrapper: HTMLElement | null =
    document.getElementById('shareButtons');
  share: Share = new Share();
  error: Error = new Error();
  hasAudio: boolean;
  shareUrl: audioData;
  lang: string;
  name: string;
  time: number;

  constructor() {
    this.time = Date.now();
    this.form = document.getElementById('sayMyName') as HTMLFormElement;
    this.form?.addEventListener('change', () => {
      this.hasAudio = false;
      this.share.shareUrl = null;
      this.setShareWrapper(true);
    });
    this.form?.addEventListener('submit', this.submit);

    const nameInput = document.getElementById('name') as HTMLInputElement;

    nameInput?.addEventListener('focus', (e) => {
      nameInput.placeholder = '';
    });

    this.setLang();
    this.setButtonListeners();
  }

  setButtonListeners() {
    if (this.shareQrButton) {
      this.shareQrButton.addEventListener('click', async (e) => {
        this.openModal(e, 'shareQrTab');
      });
    }

    if (this.shareLinkButton) {
      this.shareLinkButton.addEventListener('click', async (e) => {
        this.openModal(e, 'shareLinkTab');
      });
    }
  }

  openModal(e: MouseEvent, tab: string) {
    e.preventDefault();
    this.analytics.sendEvent('share_button_click', { tab });

    if (this.name && this.lang) {
      this.modal.openModal(e.currentTarget as HTMLElement);
      this.share.setSelectedTab(tab);
      this.share.getShareUrl(this.name, this.lang);
    }
  }

  setLang() {
    const urlLang = window.location.pathname.split('/')[2];
    const browserLang = urlLang || navigator.language;
    const select = document.getElementById('lang') as HTMLSelectElement;
    //support single langs [...new Set(chars)];
    const browserLangNorm = [
      ...new Set(browserLang.toLowerCase().split('-')),
    ].join('-');

    const hasLang = Array.from(select.options).find((option) =>
      option.value.includes(browserLangNorm)
    );

    if (hasLang) {
      hasLang.selected = true;
    }
  }

  submit = async (e: SubmitEvent) => {
    e.preventDefault();
    this.error.clearError();

    const submitTime = Date.now() - this.time;

    if (submitTime < 1000) {
      this.analytics.sendEvent('too_fast', {
        name: this.name,
        lang: this.lang,
        time: submitTime,
      });

      return this.error.setError(
        'Something went wrong, please submit your name again.'
      );
    }

    if (this.hasAudio && this.shareUrl?.duration) {
      this.analytics.sendEvent('submit_name_repeat', {
        name: this.name,
        lang: this.lang,
      });

      this.waves.initWaves(
        this.shareUrl?.duration,
        this.initWavesCallback.bind(this)
      );
      this.sayMyName.audio.play();
      return;
    }

    if (this.form) {
      const formData = new FormData(this.form);

      const lastName = String(formData.get('last_name'));
      if (lastName) {
        this.analytics.sendEvent('honeypot', {
          name: lastName,
        });

        return;
      }

      this.name = String(formData.get('name')).trim();
      this.lang = String(formData.get('lang'));

      this.analytics.sendEvent('submit_name', {
        name: this.name,
        lang: this.lang,
      });

      if (!this.name || !this.lang) {
        return this.error.setError('Please provide name and language.');
      }

      if (this.name.length > 100) {
        return this.error.setError("Name can't exceed 100 characters.", {
          name: this.name,
        });
      }

      this.playButton.loading();
      this.shareUrl = await this.sayMyName.sayName(this.lang, this.name);

      if (this.shareUrl.duration) {
        this.hasAudio = true;
        this.playButton.finishLoading();
        this.waves.initWaves(
          this.shareUrl.duration,
          this.initWavesCallback.bind(this)
        );
      }
    }
  };

  setShareWrapper(isHidden: boolean) {
    this.shareButtonsWrapper?.setAttribute('aria-hidden', String(isHidden));
    this.shareQrButton.disabled = isHidden;
    this.shareLinkButton.disabled = isHidden;
  }

  async initWavesCallback() {
    this.setShareWrapper(false);
    await delay(1200);
    this.donate.showDonate();
  }
}
