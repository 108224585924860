import Analytics from './models/Analytics';
import CopyText from './models/CopyText';
import SayNameForm from './models/SayNameForm';
import SayMyName from './models/ShowMyName';
import ContactUs from './models/ContactUs';

const contactUs = new ContactUs(document.getElementById('footerContactUs'));
const analytics = new Analytics();

if (document.getElementById('main')) {
  const sayMyName = new SayNameForm();
  const copyButtons = new CopyText();
}

if (document.getElementById('say')) {
  const name = new SayMyName();
}
