import { delay } from './helpers';

const ACTIVE_CLASS = 'sound-waves--active';

export default class SoundWaves {
  waves: HTMLElement | null;
  constructor() {
    this.waves = document.getElementById('soundWaves');
  }

  async initWaves(duration: number = 0, callback: () => void = () => {}) {
    this.waves?.classList.add(ACTIVE_CLASS);
    await delay(duration * 1000);
    this.waves?.classList.remove(ACTIVE_CLASS);
    callback();
  }
}
