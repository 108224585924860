export default class Donate {
  donateEl: HTMLElement | null;

  constructor() {
    this.donateEl = document.getElementById('donate');
  }

  showDonate() {
    this.donateEl?.setAttribute('aria-hidden', 'false');
  }
}
