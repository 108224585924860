import PlayButton from './PlayButton';
import SayMyName from './SayMyName';
import SoundWaves from './SoundWaves';
import Analytics from './Analytics';

export default class ShowMyName {
  name: string;
  lang: string;
  hasAudio: boolean;
  playButton: PlayButton = new PlayButton();
  waves: SoundWaves = new SoundWaves();
  sayMyName: SayMyName = new SayMyName('say');
  analytics = new Analytics();
  duration: number | null;

  constructor() {
    const path = document.location.pathname.split('/').reverse();
    this.name = decodeURIComponent(path[0]);
    this.lang = decodeURIComponent(path[1]);

    this.setName();

    this.playButton.playButton?.addEventListener('click', (e) => {
      this.playName();
      this.analytics.sendEvent('play_name', {
        name: this.name,
        lang: this.lang,
      });
    });
  }

  async playName() {
    if (this.hasAudio && this.duration) {
      this.waves.initWaves(this.duration);
      this.sayMyName.audio.play();
      return;
    }

    if (this.name && this.lang) {
      this.playButton.loading();

      this.duration = (
        await this.sayMyName.sayName(this.lang, this.name)
      ).duration;

      this.waves.initWaves(this.duration || 0);
      this.playButton.finishLoading();
      this.hasAudio = !!this.duration;
    }
  }

  setName() {
    const nameEl = document.getElementById('name');

    if (nameEl && this.name) {
      const nameNode = document.createTextNode(this.name);
      nameEl.appendChild(nameNode);
    }
  }
}
