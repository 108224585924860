import Error from './Error';
import Analytics from './Analytics';
import { fetchNameData } from './helpers';

export interface audioData {
  duration: number | null;
}

export default class SayMyName {
  analytics = new Analytics();
  audio: HTMLAudioElement;
  error = new Error();
  constructor(public dataType: 'data' | 'say') {
    this.audio = document.getElementById('sayMyNameAudio') as HTMLAudioElement;
  }

  async sayName(lang: string, name: string): Promise<audioData> {
    const sayErrorEl = document.getElementById('errorSay');
    sayErrorEl?.setAttribute('aria-hidden', 'true');
    this.error.clearError();

    const apiResponse = await fetchNameData(lang, name, this.dataType);

    const apiResponseData: Blob | any =
      this.dataType === 'data'
        ? await apiResponse.blob()
        : await apiResponse.json();

    if (apiResponseData.errorCode) {
      if (apiResponseData.errorCode === 6) {
        sayErrorEl?.setAttribute('aria-hidden', 'false');
        this.analytics.sendEvent('app_error', {
          error: 'Invalid name',
          details: { name, lang },
        });
        return { duration: null };
      }

      this.error.setError(apiResponseData.message, { name, lang });
      return { duration: null };
    }

    if (this.audio && apiResponseData) {
      const publicUrl: string =
        this.dataType === 'data'
          ? this.getAudioTypeBlobUrl(apiResponseData)
          : apiResponseData.publicUrl;

      this.audio.setAttribute('src', publicUrl);
      const duration: number = await new Promise((resolve, reject) => {
        this.audio.addEventListener('loadedmetadata', () => {
          resolve(this.audio.duration);
        });
      });

      this.audio.play();

      return { duration };
    }

    return { duration: null };
  }

  getAudioTypeBlobUrl(apiResponseData: BlobPart) {
    const audioBlob = new Blob([apiResponseData], { type: 'audio/mpeg' });
    return URL.createObjectURL(audioBlob);
  }
}
