import Analytics from './Analytics';
import Modal from './Modal';

export default class ContactUs {
  analytics = new Analytics();
  modal: Modal = new Modal(document.getElementById('contactModal'));

  constructor(public contactUsLink: HTMLElement | null) {
    contactUsLink?.addEventListener('click', (e) => {
      this.openModal(e);
    });
  }

  openModal(e: MouseEvent) {
    e.preventDefault();
    this.analytics.sendEvent('contact_click');

    //inject link to modal
    const contactEmail = document.querySelector('.js-contactEmail');

    if (contactEmail) {
      const email = 'contact@hearmyname.net';
      const linkEl = document.createElement('a');
      const linkText = document.createTextNode(email);
      linkEl.appendChild(linkText);
      linkEl.href = `mailto:${email}`;
      contactEmail.innerHTML = '';
      contactEmail.appendChild(linkEl);

      this.modal.openModal(e.currentTarget as HTMLElement);
    }
  }
}
