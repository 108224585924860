declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export default class Analytics {
  sendEvent(eventName: string, eventData = {}) {
    window.dataLayer.push({
      event: 'custom_event',
      event_details: eventData,
      event_type: eventName,
    });
  }
}
