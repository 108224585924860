import { fetchNameData } from './helpers';
import Analytics from './Analytics';

export default class Share {
  analytics = new Analytics();
  tabList = document.getElementById('tabList');
  tabs: NodeListOf<Element> | undefined =
    this.tabList?.querySelectorAll('.share__button');
  content: NodeListOf<Element> | undefined =
    this.tabList?.querySelectorAll('.share__content');

  loader: HTMLElement | null = document.getElementById('modalLoader');
  shareUrl: string | null;

  constructor() {
    if (this.tabs) {
      this.tabs.forEach((tab) => {
        if (tab) {
          tab.addEventListener('click', () => {
            this.setActiveTab(tab);
            this.analytics.sendEvent('tab_click', {
              tab: tab.getAttribute('aria-controls'),
            });
          });
        }
      });
    }
  }

  closeAllTabs() {
    if (this.tabs && this.content) {
      this.tabs.forEach((el) => {
        el.setAttribute('tabindex', '-1');
        el.setAttribute('aria-selected', 'false');
      });

      this.content.forEach((el) => {
        el.classList.add('hidden');
      });
    }
  }

  setActiveTab(activeTab: Element) {
    const contentId = activeTab.getAttribute('aria-controls');
    this.closeAllTabs();
    if (contentId) {
      const contentEl = document.getElementById(contentId);
      activeTab.setAttribute('tabindex', '0');
      activeTab.setAttribute('aria-selected', 'true');
      contentEl?.classList.remove('hidden');
    }
  }

  setSelectedTab(activeTabId: string) {
    const tab = document.getElementById(activeTabId);
    if (tab) {
      this.setActiveTab(tab);
    }
  }

  //sets values for share link and qr
  setShareVariables() {
    const shareLinkText: HTMLAnchorElement | null = document.getElementById(
      'shareLinkText'
    ) as HTMLAnchorElement | null;

    const fullUrl = `${window.location.origin}${this.shareUrl}`;
    const friendlyFullUrl = decodeURIComponent(
      `${window.location.host}${this.shareUrl}`
    );

    if (shareLinkText) {
      shareLinkText.href = fullUrl;
      shareLinkText.textContent = decodeURIComponent(friendlyFullUrl);

      const shareLinkCopyButton = document.getElementById('shareLinkTextCopy');
      if (shareLinkCopyButton) {
        shareLinkCopyButton.dataset.copy = fullUrl;
      }
    }

    const codeWrapper = document.getElementById('shareQrCode');
    if (codeWrapper) {
      codeWrapper.innerHTML = '';

      // @ts-ignore
      new QRCode(codeWrapper, {
        text: fullUrl,
        width: 128,
        height: 128,
        colorDark: '#000000',
        colorLight: '#ffffff',
        // @ts-ignore
        correctLevel: QRCode.CorrectLevel.H,
      });
    }

    this.tabList?.setAttribute('aria-hidden', 'false');
    this.loader?.classList.remove('is-active');
  }

  //gets url for share
  async getShareUrl(name: string, lang: string) {
    if (this.shareUrl) {
      this.setShareVariables();
      return;
    }

    this.tabList?.setAttribute('aria-hidden', 'true');
    this.loader?.classList.add('is-active');

    const apiResponse = await fetchNameData(lang, name, 'url');
    const apiResponseData = await apiResponse.json();

    if (apiResponseData) {
      this.shareUrl = apiResponseData.shareUrl;

      if (this.shareUrl) {
        //set share params for share components
        this.setShareVariables();
      }
    }
  }
}
