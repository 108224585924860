import Analytics from './Analytics';

export default class Error {
  errorEl: HTMLElement | null;
  analytics = new Analytics();

  constructor(public errorElId: string = 'mainError') {
    this.errorEl = document.getElementById(errorElId);
  }

  setError(message: string, details = {}) {
    if (this.errorEl) {
      this.errorEl.textContent = message;
      this.errorEl.setAttribute('aria-hidden', 'false');
      this.analytics.sendEvent('app_error', { message, details });
    }
  }

  clearError() {
    if (this.errorEl) {
      this.errorEl.textContent = '';
      this.errorEl.setAttribute('aria-hidden', 'true');
    }
  }
}
