export default class PlayButton {
  playButton: HTMLElement | null = document.getElementById('playButton');
  loader: HTMLElement | null = document.getElementById('loader');

  loading() {
    if (this.loader && this.playButton) {
      this.loader.classList.add('is-active');
      this.playButton.classList.add('hidden');
    }
  }

  finishLoading() {
    if (this.loader && this.playButton) {
      this.loader.classList.remove('is-active');
      this.playButton.classList.remove('hidden');
    }
  }
}
