const API_URLS = {
  url: '/api/url',
  data: '/api/saydata',
  say: '/api/say',
};

export const fetchNameData = async (
  lang: string,
  name: string,
  dataType: 'say' | 'data' | 'url'
) => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      name,
      lang,
    }),
  };

  return await fetch(API_URLS[dataType], options);
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
