import Analytics from './Analytics';

export default class Modal {
  focusableElements: NodeListOf<HTMLElement> | undefined;
  closeFocusEl: HTMLElement | undefined;
  analytics = new Analytics();

  constructor(public modalEl: HTMLElement | null) {
    if (this.modalEl) {
      const dialogOverLay = modalEl?.querySelector('.js-dialogOverlay');
      dialogOverLay?.addEventListener('click', this.dialogOverLayClick);

      modalEl
        ?.querySelector('.js-dialogCloseButton')
        ?.addEventListener('click', () => {
          this.closeModal();
        });
    }
  }

  keyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        this.closeModal();
        break;
      case 'Tab':
        this.trapFocus(e);
    }
  };

  dialogOverLayClick = (e: Event) => {
    if (
      e.target &&
      e.target instanceof HTMLElement &&
      !e.target.closest('.dialog__body')
    )
      this.closeModal();
  };

  trapFocus = (e: KeyboardEvent) => {
    if (this.focusableElements && this.focusableElements.length) {
      const firstFocusableElement = this.focusableElements[0];
      const lastFocusableElement =
        this.focusableElements[this.focusableElements.length - 1];

      if (e.shiftKey) {
        /* shift + tab */
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } /* tab */ else {
        if (document.activeElement === lastFocusableElement) {
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus(); // add focus for the first focusable element
          e.preventDefault();
        }
      }
    }
  };

  openModal = (closeFocusEl: HTMLElement) => {
    this.closeFocusEl = closeFocusEl;
    this.analytics.sendEvent('modal_opened');

    const bodyElements = document.body.children;
    document.body.classList.add('scroll-lock');

    for (let bodyEl of bodyElements) {
      bodyEl.setAttribute('aria-hidden', 'true');
    }

    this.modalEl?.setAttribute('aria-hidden', 'false');
    this.focusableElements = this.modalEl?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    if (this.focusableElements?.length) {
      (this.focusableElements[0] as HTMLElement).focus();
    }

    document.addEventListener('keydown', this.keyDown);
  };

  closeModal = () => {
    const bodyElements = document.body.children;
    const modalElements = document.querySelectorAll('.dialog');
    document.body.classList.remove('scroll-lock');

    for (let bodyEl of bodyElements) {
      bodyEl.removeAttribute('aria-hidden');
    }

    for (let modalEl of modalElements) {
      modalEl?.setAttribute('aria-hidden', 'true');
    }

    document.removeEventListener('keydown', this.keyDown);
    this.closeFocusEl?.focus();
  };
}
