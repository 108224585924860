import Analytics from './Analytics';
import { delay } from './helpers';

export default class CopyText {
  analytics = new Analytics();

  constructor() {
    const copyButtons = document.querySelectorAll('.js-copy');
    copyButtons.forEach((button) => {
      button.addEventListener('click', this.copy);
    });
  }

  async updateIcon(button: HTMLElement) {
    const svgRef = button.querySelector('use');
    if (svgRef) {
      svgRef.setAttribute('xlink:href', '#icon-check');

      await delay(1000);
      svgRef.setAttribute('xlink:href', '#icon-copy');
    }
  }

  copy = (e: Event) => {
    e.preventDefault();
    if (e.currentTarget && e.currentTarget instanceof HTMLElement) {
      const button = e.currentTarget;
      const text = button.dataset?.copy;

      if (text) {
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '-9999px';
        textArea.style.position = 'absolute';

        document.body.appendChild(textArea);
        textArea.select();

        try {
          document.execCommand('copy');
          this.updateIcon(button);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
          this.analytics.sendEvent('js_error', err);
        }

        this.analytics.sendEvent('copy', { text });

        document.body.removeChild(textArea);
      }
    }
  };
}
